






























































































































.lab-chart-block-ln{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 16px;
  .title{
    margin-bottom: 2px;
  }
  .number{
    &.dark{
      color: #161616;
    }
    &.green{
      color: #02BB9F;
    }
  }
  .chart{
    max-width: 250px;
    position: relative;
    height: 50px;
    > div{
      position: relative;
      height: 50px;
    }
  }
}
