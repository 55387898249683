















































































































































































































































































































































































































































































































































































.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
.lab-chart-block-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  .lab-chart-block{
    flex: 1;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.lab-chart-block-ln-wrapper{
  display: flex;
  flex-direction: column;
  height: 100%;
  .lab-chart-block-ln{
    flex: 1;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.lab-country{
  &-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .item {
      margin-right: 16px;
      max-width: 25%;
      &:last-child {
        margin-right: 0;
      }
      .title {
        margin-bottom: 4px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .number {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  &-chart{
    height: 300px;
    > div{
      position: relative;
      height: 300px;
    }
  }
}
.lab-grafic-list{
  li{
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    &:last-child{
      margin-bottom: 0;
    }
  }
  .circle{
    width: 8px;
    flex: 0 0 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .text-in{
    margin-right: 8px;
    max-width: 85%;
  }
  .lab-grafic-lf{
    display: flex;
    align-items: center;
    > span{
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.doughnut-chart{
  &-wrapper{
    max-width: 280px;
    position: relative;
    z-index: 0;
  }
  &-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 15px 40px;
    width: 100%;
    z-index: -1;
    .title{
      margin-bottom: 4px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

